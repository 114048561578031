'use client'

import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'

export default function App() {
  const [timeLeft, setTimeLeft] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  })

  useEffect(() => {
    const targetDate = new Date('2024-10-26T16:00:00Z') // 'Z' denotes UTC time

    const interval = setInterval(() => {
      const now = new Date()
      const difference = targetDate.getTime() - now.getTime()

      const days = Math.floor(difference / (1000 * 60 * 60 * 24)).toString().padStart(2, '0')
      const hours = Math.floor((difference / (1000 * 60 * 60) % 24)).toString().padStart(2, '0')
      const minutes = Math.floor((difference / 1000 / 60) % 60).toString().padStart(2, '0')
      const seconds = Math.floor((difference / 1000) % 60).toString().padStart(2, '0')

      setTimeLeft({ days, hours, minutes, seconds })

      if (difference < 0) {
        clearInterval(interval)
        setTimeLeft({ days: '00', hours: '00', minutes: '00', seconds: '00' })
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-black font-mono p-4">
      <motion.h1
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="text-3xl text-center sm:text-4xl md:text-6xl font-bold text-white mb-6 sm:mb-8"
      >
        Global War Party <br/>
      </motion.h1>
      <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Square_compasses.svg/1280px-Square_compasses.svg.png" className='invertImage' />
      <div className="flex flex-col sm:flex-row sm:flex-wrap justify-center space-y-4 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
        {Object.entries(timeLeft).map(([unit, value]) => (
          <motion.div
            key={unit}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className="flex flex-col items-center w-full sm:w-auto"
          >
            <div className="text-3xl sm:text-4xl md:text-5xl font-bold text-black bg-white rounded-lg p-4 mb-2 w-full sm:w-24 h-24 flex items-center justify-center">
              {value}
            </div>
            <span className="text-lg md:text-base text-white">
              {unit === 'days' && 'days'}
              {unit === 'hours' && 'hours'}
              {unit === 'minutes' && 'minutes'}
              {unit === 'seconds' && 'seconds'}
            </span>
          </motion.div>
        ))}
      </div>
    </div>
  )
}